














































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { CorpEntityModel, CorpQueryModel } from '@common-src/entity-model/corp-entity';
import CorpService from '@common-src/service/corp';
import CorpDetailComponent from './corp-detail-drawer.vue';
import { PermissionType } from '@common-src/model/enum';

@Component({
    components: {
        'corp-detail-drawer': CorpDetailComponent
    }
})
export default class CorpListComponent extends TableDialogFormComponent<CorpEntityModel, CorpQueryModel> {
    CorpEntityModel = CorpEntityModel;
    created() {
        this.initTable({
            service: CorpService,
            queryModel: new CorpQueryModel(),
            tableColumns: CorpEntityModel.getTableColumns()
        });
        this.getList();
    }
    configClick(model: CorpEntityModel) {
        (this.$refs.corpDetailDrawer as CorpDetailComponent).drawerRoleOpen(model.id, PermissionType.PLATFORM, 'USER', 'PROJECT');
        (this.$refs.corpDetailDrawer as CorpDetailComponent).drawerTitle = model.name;
    }
}

